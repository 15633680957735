.wrapper {
    position: relative;
    flex-grow: 1;
    margin: auto;
    max-width: auto;
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-template-rows: repeat(4,1fr);
    grid-gap: 5vmin;
    justify-items: center;
    align-items: center;
}

.wrapper img{
    z-index: -1;
    grid-column: span 2;
    max-width: 100%;
    margin-bottom: -52%;
    clip-path: polygon( 0%, 50%, 100%, 50%);
    transform: scale(1);
    transition: all .25s;
}

.wrapper .honeycomb{
    z-index: 1;
    grid-column: span 2;
    max-width: 80%;
    margin-bottom: -50%;
    clip-path: polygon( 0%, 50%, 100%, 50%);
    transform: scale(1);
    transition: all ease-in-out .25s;
    
}

.wrapper .honeycomb1{
    z-index: 1;
    grid-column: span 2;
    max-width: 400px;
    margin-bottom: -52%;
    clip-path: polygon( 0%, 50%, 100%, 50%);
    transform: scale(1);
    transition: all ease-in-out .25s;
}

.wrapper img:nth-child(7n + 1){
    grid-column: 2 / span 2;
}

.wrapper img:hover {
    z-index: 5;
    transform: scale(1.1);
    overflow: hidden;
}

@font-face {
    font-family: "Inter-Regular";
    src: url(Font/Inter-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}

  
  
  
/*--------------Button Explorer---------------*/

.tapered-corners {
    border-bottom-right-radius: 12px; /* Adjust as needed */
    border-bottom-left-radius: 12px;
  }

  
  